import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';

// Carousel blocks in page body matrix field
(function() {
  let carouselContainers = document.querySelectorAll('[data-carousel]');
  let carousels = [];

  if (carouselContainers) {
    let flktyOptions = {
      "wrapAround":true,
      "cellAlign":"left",
      "prevNextButtons":true,
      "pageDots":false,
      "autoPlay":3000
    };

    let widthOptions = [
      {
        "width": 0,
        "slides": 1
      },
      {
        "width": 500,
        "slides": 2
      },
      {
        "width": 750,
        "slides": 3
      },
      {
        "width": 1000,
        "slides": 4
      },
      {
        "width": 1250,
        "slides": 5
      },
    ];

    const checkSize = function(carousel, index) {
      let initFlkty = true;
      if (carousel) {
        let numSlides = carousel.dataset.carousel;

        if (numSlides) {
          widthOptions.forEach(function(opt) {
            if (window.innerWidth >= opt.width && numSlides <= opt.slides) {
              initFlkty = false;
            }
          });
          if (carousels[index]) {
            carousels[index].destroy();
            carousels[index] = null;
          }
          if (initFlkty) {
            carousels[index] = new Flickity(carousel, flktyOptions);
            carousel.classList.remove('flex','justify-center');
            let slides = carousel.querySelectorAll('.flickity-cell');
            Array.prototype.forEach.call(slides, function(slide) {
              slide.classList.add('absolute');
            });
          } else {
            carousel.classList.add('flex','justify-center');
            carousel.style = '';
            let slides = carousel.children;
            Array.prototype.forEach.call(slides, function(slide) {
              slide.classList.remove('absolute');
            });
          }
        }
      }
    };

    Array.prototype.forEach.call(carouselContainers, function(carousel, index) {
      checkSize(carousel, index);
    });

    window.addEventListener('resize', function(ev) {
      Array.prototype.forEach.call(carouselContainers, function(carousel, index) {
        checkSize(carousel, index);
      });
    });
  }
})();
